import React, {Component} from 'react';
import {IProps, IState} from "./types";
import Sound from 'react-sound';
const callSound = require('../../assets/sounds/call.mp3')

class CallSound extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            status: 'STOPPED'
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.playCall !== this.props.playCall) {
            this.setState({
                status: (this.props.playCall ? 'PLAYING' : 'STOPPED')
            })
        }
    }


    render() {
        return (<div>
            <Sound
                url={callSound}
                playStatus={this.state.status ? this.state.status : 'STOPPED'}
                loop={true}
                volume={20}
            />
        </div>)
    }


}

export default CallSound;