import React, {Component} from 'react';
import {IProps, IState} from "./types";
import Sound from 'react-sound';

const messageSound = require('../../assets/sounds/message.mp3')

class MessageSound extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            status: 'STOPPED'
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.playMessage !== this.props.playMessage) {
            this.setState({
                status: (this.props.playMessage ? 'PLAYING' : 'STOPPED')
            })
        }
    }


    render() {
        return (<div>
            <Sound
                url={messageSound}
                playStatus={this.state.status ? this.state.status : 'STOPPED'}
                volume={20}
            />

        </div>)
    }


}

export default MessageSound;