import Janus from './janus'

var creatingSubscription = false;

class VideoRoom {
    VideoController = null
    roomId = null
    janusToken = null
    roomToken = null
    mypvtid = null
    username = ''

    sfutest = null
    remoteFeed = null

    bitrateTimer = []
    feeds = {}
    feedStreams = {}
    subStreams = {}
    slots = {}
    mids = {}
    subscriptions = {}
    localTracks = {}
    remoteTracks = {}
    localVideos = 0
    localAudio = null

    document = null
    _janus = null

    opaqueId = "medconnectroom-" + Janus.randomString(12)
    localVideo = null
    remoteVideo = null
    remoteAudio = null

    url = null

    simulcastStarted = {}
    acodec = null;
    vcodec = null;
    // vcodec = 'h264'

    doDtx = false;

    use_msid = false;
    mytracks = [];

    doSimulcast = true;

    constructor(VideoController) {
        this.VideoController = VideoController
    }


    initValues(vars) {
        this.document = vars.document
        this.username = vars.username
        this.localVideo = vars.localVideo
        this.remoteVideo = vars.remoteVideo
        this.remoteAudio = vars.remoteAudio
        Janus.updateVars(vars)
        this.onBeforeUnload()
    }

    tryReconnect() {
        var self = this
        setTimeout(() => {
            if (navigator.onLine) {
                var url = self.url
                var janusId = self.roomId
                var janusToken = self.janusToken
                var roomToken = self.roomToken
                self.destroyJanus()
                self.joinRoom(url, janusId, janusToken, roomToken, true)
                return;
            }
            self.tryReconnect()
        }, 1000)
    }

    joinRoom(url, janusId, token, roomToken, iceRestart) {

        if (!Janus.isWebrtcSupported()) {
            const janusError = new CustomEvent('janus.error', {detail: {data: "Webrtc is not supported"}})
            window.dispatchEvent(janusError)
            return
        }

        this.url = url
        this.roomId = Number(janusId)
        this.janusToken = token;
        this.roomToken = roomToken;

        var self = this

        Janus.init({
            debug: process.env.REACT_APP_JANUS_DEBUG > 0,
            callback: function () {
                self._janus = new Janus({
                    server: self.url,
                    token: `${token}`,
                    bundlePolicy: 'max-bundle',
                    iceServers: [
                        {urls: process.env.REACT_APP_STUN_URLS},
                        {
                            urls: process.env.REACT_APP_TURN_URLS,
                            credential: process.env.REACT_APP_TURN_CREDENTIAL,
                            username: process.env.REACT_APP_TURN_USERNAME
                        }
                    ],
                    success: function () {
                        if (!self._janus) {
                            setTimeout(500);
                            return this.success();
                        }

                        self._janus.attach({
                            plugin: "janus.plugin.videoroom",
                            opaqueId: self.opaqueId,
                            success: function (pluginHandle) {
                                self.sfutest = pluginHandle;
                                self.registerUsername(self.username, roomToken)
                            },
                            error: function (error) {
                                Janus.error("  -- Error attaching plugin...", error);
                                const janusError = new CustomEvent('janus.error', {detail: {data: error}})
                                window.dispatchEvent(janusError)
                            },
                            consentDialog: function (on) {
                                Janus.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
                            },
                            iceState: function (state) {
                                Janus.log("ICE state changed to " + state);
                                let videoIssue = null;
                                if (state === 'disconnected') {
                                    videoIssue = new CustomEvent('janus.videoIssue', {detail: {data: "Our track is gone", type: 'ourFeedIssue'}})
                                } else {
                                    videoIssue = new CustomEvent('janus.videoIssue', {detail: {data: null, type: false}})
                                }

                                if (videoIssue) {
                                    window.dispatchEvent(videoIssue)
                                }
                            },
                            connectionState: function (state) {
                                Janus.log("CONNECTION state changed to " + state);
                                if (state  === 'failed') {
                                    self.tryReconnect()
                                }
                            },
                            mediaState: function (medium, on, mid) {
                                Janus.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
                            },
                            webrtcState: function (on) {
                                Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                            },
                            slowLink: function (uplink, lost, mid) {
                                Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
                                    " packets on mid " + mid + " (" + lost + " lost packets)");
                            },
                            onmessage: function (msg, jsep) {
                                Janus.debug(" ::: Got a message (publisher) :::", msg);
                                var event = msg["videoroom"];
                                Janus.debug("Event: " + event);
                                if (event != undefined && event != null) {
                                    if (event === "joined") {
                                        // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                                        self.myid = msg["id"];
                                        self.mypvtid = msg["private_id"];
                                        Janus.log("Successfully joined room " + msg["room"] + " with ID " + self.myid);
                                        self.publishOwnFeed(true, iceRestart);
                                        // Any new feed to attach to?
                                        if (msg["publishers"]) {
                                            self.eachPublisher(msg['publishers'])
                                        }
                                    } else if (event === "destroyed") {
                                        // The room has been destroyed
                                    } else if (event === "event") {
                                        // Any info on our streams or a new feed to attach to?
                                        if (msg["streams"]) {
                                            var streams = msg["streams"];
                                            for (var i in streams) {
                                                var stream = streams[i];
                                                stream["id"] = self.myid;
                                                stream["display"] = self.username;
                                            }
                                            self.feedStreams[self.myid] = {
                                                id: self.myid,
                                                display: self.username,
                                                streams: streams
                                            }
                                        } else if (msg["publishers"]) {
                                            self.eachPublisher(msg["publishers"])
                                        } else if (msg["leaving"]) {
                                            // One of the publishers has gone away?
                                            var leaving = msg["leaving"];
                                            Janus.log("Publisher left: " + leaving);
                                            self.unsubscribeFrom(leaving);
                                        } else if (msg["unpublished"]) {
                                            // One of the publishers has unpublished?
                                            var unpublished = msg["unpublished"];
                                            Janus.log("Publisher left: " + unpublished);
                                            if (unpublished === 'ok') {
                                                // That's us
                                                self.sfutest.hangup();
                                                return;
                                            }
                                            self.unsubscribeFrom(unpublished);
                                        } else if (msg["error"]) {
                                            if (msg["error_code"] === 426) {
                                                const janusError = new CustomEvent('janus.error', {
                                                    detail: {
                                                        data: "Pokój czatu został zamknięty. Prosimy spróbować ponownie."
                                                    }
                                                })
                                                window.dispatchEvent(janusError)
                                            } else {
                                                const janusError = new CustomEvent('janus.error', {detail: {data: msg["error"]}})
                                                window.dispatchEvent(janusError)
                                            }
                                        }
                                    }
                                }
                                if (jsep) {
                                    Janus.debug("Handling SDP as well...", jsep);
                                    self.sfutest.handleRemoteJsep({jsep: jsep});
                                }
                            },
                            onlocaltrack: function(track, on) {
                                Janus.debug(" ::: Got a local track event :::");
                                Janus.debug("Local track " + (on ? "added" : "removed") + ":", track);
                                // We use the track ID as name of the element, but it may contain invalid characters
                                self.mytracks.push(track)
                                var trackId = track.id.replace(/[{}]/g, "");
                                if(!on) {
                                    // Track removed, get rid of the stream and the rendering
                                    var stream = self.localTracks[trackId];
                                    if(stream) {
                                        try {
                                            stream.getTracks().forEach((track) => {

                                                track.stop()
                                            });
                                        } catch(e) {}
                                    }
                                    if(track.kind === "video") {
                                        self.localVideos--;
                                    }
                                    delete self.localTracks[trackId];
                                    return;
                                }
                                // If we're here, a new track was added
                                var stream = self.localTracks[trackId];
                                if(stream) {
                                    // We've been here already
                                    return;
                                }

                                if(track.kind === "audio") {
                                    stream = new MediaStream();
                                    stream.addTrack(track);
                                    self.localAudio = stream;
                                } else {
                                    // New video track: create a stream out of it
                                    self.localVideos++;
                                    stream = new MediaStream();
                                    stream.addTrack(track);
                                    self.localTracks[trackId] = stream;
                                    Janus.log("Created local stream:", stream);
                                    Janus.log(stream.getTracks());
                                    Janus.log(stream.getVideoTracks());
                                    Janus.attachMediaStream(self.localVideo, stream);
                                }
                                if(self.sfutest.webrtcStuff.pc.iceConnectionState !== "completed" &&
                                    self.sfutest.webrtcStuff.pc.iceConnectionState !== "connected") {
                                    //I DONT KNOW WHAT TO DO HERE
                                }
                            },
                            onremotetrack: function (track, mid, on) {
                                // The publisher stream is sendonly, we don't expect anything here
                            },
                            oncleanup: function () {
                                Janus.log(" ::: Got a cleanup notification: we are unpublished now :::");
                                delete self.feedStreams[self.myid];
                                self.localTracks = {};
                                self.localVideos = 0;
                            }
                        });
                    },
                    error: function (error) {
                        Janus.error(error);
                        const janusError = new CustomEvent('janus.error', {detail: {data: error}})
                        window.dispatchEvent(janusError)
                    },
                    destroyed: function () {
                    }
                });
            }
        });
    }

    eachPublisher(list) {
        const self = this
        Janus.debug("Got a list of available publishers/feeds:", list);

        let sources = [];
        for (const f in list) {
            const id = list[f]["id"];
            const display = list[f]["display"];
            const streams = list[f]["streams"];
            for (const i in streams) {
                const stream = streams[i];
                stream["id"] = id;
                stream["display"] = display;
            }
            self.feedStreams[id] = {
                id: id,
                display: display,
                streams: streams
            }
            Janus.debug("  >> [" + id + "] " + display + ":", streams);
            if (!sources)
                sources = [];

            sources.push(streams);
        }

        if (sources.length) {
            self.subscribeTo(sources);
        }
    }

    unpublishOwnFeed() {
        var unpublish = { request: "unpublish" };
        this.sfutest.send({ message: unpublish });
    }

    destroyJanus() {
        if (this.sfutest){
            this.unpublishOwnFeed()
            this.sfutest.hangup()
            // this.sfutest.detach()
        }

        if (this.remoteFeed) {
            this.remoteFeed.hangup()
            // this.remoteFeed.detach()
        }

        if (this._janus) {
            this._janus.destroy({
                unload: true,
                cleanupHandles: true
            })

            delete this._janus
            delete this.sfutest
            delete this.remoteFeed
            this._janus = null
            this.sfutest = null;
            this.remoteFeed = null;
            this.roomId = null
        }
        if (this.localAudio) {
            this.localAudio.getTracks().forEach(track => {
                track.stop()
            })
            this.localAudio = null
        }

        if (this.localTracks) {
            for (const i in this.localTracks) {
                this.localTracks[i].getTracks().forEach(track => {
                    track.stop()
                })
            }
            this.localTracks = {}
        }

        if (this.mytracks.length) {
            this.mytracks.forEach(track => {
                track.stop();
            })
            this.mytracks = [];
        }

        this.bitrateTimer = []
        this.feeds = {}
        this.mids = {}
        this.myid = null
        this.mypvtid = null
        this.slots = {}
        this.subStreams = {}
        this.opaqueId = "medconnectroom-" + Janus.randomString(12)
        creatingSubscription = false
    }

    onBeforeUnload() {
        const self = this
        window.addEventListener('beforeunload', function() {
            self.destroyJanus();
        })
    }

    registerUsername(username, roomToken) {
        var register = {
            request: "join",
            room: this.roomId,
            ptype: "publisher",
            display: `${username}`,
            token: roomToken.trim()
        };
        this.sfutest.send({message: register});
    }

    publishOwnFeed(useAudio, iceRestart) {
        const self = this

        let tracks = [];
        if(useAudio) {
            tracks.push({ type: 'audio', capture: true, recv: false });
        }

        tracks.push({ type: 'video', capture: true, recv: false, simulcast: self.doSimulcast });

        self.sfutest.createOffer({
            iceRestart: (iceRestart ? true : null),
            tracks: tracks,
            customizeSdp: function(jsep) {
                // If DTX is enabled, munge the SDP
                if(self.doDtx) {
                    jsep.sdp = jsep.sdp
                        .replace("useinbandfec=1", "useinbandfec=1;usedtx=1")
                }
            },
            success: function (jsep) {
                Janus.debug("Got publisher SDP!");
                Janus.debug(jsep);
                var publish = {
                    request: "configure",
                    audio: useAudio,
                    video: true,
                    bitrate_cap: false,
                    fir_freq: 0
                };
                if (self.acodec)
                    publish["audiocodec"] = self.acodec;
                if (self.vcodec)
                    publish["videocodec"] = self.vcodec;
                self.sfutest.send({message: publish, jsep: jsep});
            },
            error: function (error) {
                Janus.error("WebRTC error:", error);
                if (useAudio) {
                    self.publishOwnFeed(false, iceRestart);
                } else {
                    const janusError = new CustomEvent('janus.error', {detail: {data: error}})
                    window.dispatchEvent(janusError)
                }
            }
        });
    }

    subscribeTo(sources) {
        const self = this
        // Check if we're still creating the subscription handle
        if (creatingSubscription) {
            // Still working on the handle, send this request later when it's ready
            setTimeout(function () {
                self.subscribeTo(sources);
            }, 500);
            return;
        }

        if (self.remoteFeed) {
            // streams the feeds are publishing, so we can choose what to pick or skip
            var subscription = [];
            for (var s in sources) {
                var streams = sources[s];
                for (var i in streams) {
                    var stream = streams[i];
                    // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
                    if (stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                        (stream.codec === "vp9" || (stream.codec === "vp8" && !Janus.safariVp8))) {
                        console.log("Publisher is using " + stream.codec.toUpperCase +
                            ", but Safari doesn't support it: disabling video stream #" + stream.mindex);
                        continue;
                    }
                    if (stream.disabled) {
                        Janus.log("Disabled stream:", stream);
                        // TODO Skipping for now, we should unsubscribe
                        continue;
                    }
                    if (self.subscriptions[stream.id] && self.subscriptions[stream.id][stream.mid]) {
                        Janus.log("Already subscribed to stream, skipping:", stream);
                        continue;
                    }
                    // Find an empty slot in the UI for each new source
                    if (!self.feedStreams[stream.id].slot) {
                        var slot;
                        for (var i = 1; i < 6; i++) {
                            if (!self.feeds[i]) {
                                slot = i;
                                self.feeds[slot] = stream.id;
                                self.feedStreams[stream.id].slot = slot;
                                self.feedStreams[stream.id].remoteVideos = 0;
                                break;
                            }
                        }
                    }
                    subscription.push({
                        feed: stream.id,	// This is mandatory
                        mid: stream.mid		// This is optional (all streams, if missing)
                    });
                    if (!self.subscriptions[stream.id])
                        self.subscriptions[stream.id] = {};
                    self.subscriptions[stream.id][stream.mid] = true;
                }
            }
            if (subscription.length === 0) {
                // Nothing to do
                return;
            }
            self.remoteFeed.send({
                message: {
                    request: "subscribe",
                    streams: subscription
                }
            });
            // Nothing else we need to do
            return;
        }
        // If we got here, we're creating a new handle for the subscriptions (we only need one)
        creatingSubscription = true;
        self._janus.attach(
            {
                plugin: "janus.plugin.videoroom",
                opaqueId: self.opaqueId,
                success: function (pluginHandle) {
                    self.remoteFeed = pluginHandle;
                    self.remoteTracks = {};
                    Janus.log("Plugin attached! (" + self.remoteFeed.getPlugin() + ", id=" + self.remoteFeed.getId() + ")");
                    Janus.log("  -- This is a multistream subscriber");
                    // Prepare the streams to subscribe to, as an array: we have the list of
                    // streams the feed is publishing, so we can choose what to pick or skip
                    var subscription = [];
                    for (var s in sources) {
                        var streams = sources[s];
                        for (var i in streams) {
                            var stream = streams[i];
                            // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
                            if (stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                                (stream.codec === "vp9" || (stream.codec === "vp8" && !Janus.safariVp8))) {
                                console.log("Publisher is using " + stream.codec.toUpperCase +
                                    ", but Safari doesn't support it: disabling video stream #" + stream.mindex);
                                continue;
                            }
                            if (stream.disabled) {
                                Janus.log("Disabled stream:", stream);
                                // TODO Skipping for now, we should unsubscribe
                                continue;
                            }
                            Janus.log("Subscribed to " + stream.id + "/" + stream.mid + "?", self.subscriptions);
                            if (self.subscriptions[stream.id] && self.subscriptions[stream.id][stream.mid]) {
                                Janus.log("Already subscribed to stream, skipping:", stream);
                                continue;
                            }
                            // Find an empty slot in the UI for each new source
                            if (!self.feedStreams[stream.id].slot) {
                                var slot;
                                for (var i = 1; i < 6; i++) {
                                    if (!self.feeds[i]) {
                                        slot = i;
                                        self.feeds[slot] = stream.id;
                                        self.feedStreams[stream.id].slot = slot;
                                        self.feedStreams[stream.id].remoteVideos = 0;
                                        break;
                                    }
                                }
                            }
                            subscription.push({
                                feed: stream.id,	// This is mandatory
                                mid: stream.mid		// This is optional (all streams, if missing)
                            });
                            if (!self.subscriptions[stream.id])
                                self.subscriptions[stream.id] = {};
                            self.subscriptions[stream.id][stream.mid] = true;
                        }
                    }
                    // We wait for the plugin to send us an offer
                    var subscribe = {
                        request: "join",
                        room: self.roomId,
                        ptype: "subscriber",
                        streams: subscription,
                        use_msid: self.use_msid,
                        private_id: self.mypvtid
                    };
                    self.remoteFeed.send({message: subscribe});
                },
                error: function (error) {
                    Janus.error("  -- Error attaching plugin...", error);
                },
                iceState: function (state) {
                    Janus.log("ICE state (remote feed) changed to " + state);
                },
                connectionState: function (state) {
                    Janus.log("CONNECTION state (remote feed) changed to " + state);
                },
                webrtcState: function (on) {
                    Janus.log("Janus says this WebRTC PeerConnection (remote feed) is " + (on ? "up" : "down") + " now");
                },
                slowLink: function (uplink, lost, mid) {
                    Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
                        " packets on mid " + mid + " (" + lost + " lost packets)");
                },
                onmessage: function (msg, jsep) {
                    Janus.debug(" ::: Got a message (subscriber) :::", msg);
                    var event = msg["videoroom"];
                    Janus.debug("Event: " + event);
                    if (msg["error"]) {
                        console.log(msg["error"]);
                    } else if (event) {
                        if (event === "attached") {
                            // Now we have a working subscription, next requests will update this one
                            creatingSubscription = false;
                            Janus.log("Successfully attached to feed in room " + msg["room"]);
                        } else if (event === "event") {
                            // Check if we got an event on a simulcast-related event from this publisher
                            var mid = msg["mid"];
                            var substream = msg["substream"];
                            var temporal = msg["temporal"];
                            if ((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                                // Check which this feed this refers to
                                var sub = self.subStreams[mid];
                                var slot = self.slots[mid];
                                if (!self.simulcastStarted[slot]) {
                                    self.simulcastStarted[slot] = true;
                                }
                            }
                        } else {
                            // What has just happened?
                        }
                    }
                    if (msg["streams"]) {
                        // Update map of subscriptions by mid
                        for (var i in msg["streams"]) {
                            var mid = msg["streams"][i]["mid"];
                            self.subStreams[mid] = msg["streams"][i];
                            var feed = self.feedStreams[msg["streams"][i]["feed_id"]];
                            if (feed && feed.slot) {
                                self.slots[mid] = feed.slot;
                                self.mids[feed.slot] = mid;
                            }
                        }
                    }
                    if (jsep) {
                        Janus.debug("Handling SDP as well...", jsep);
                        var stereo = (jsep.sdp.indexOf("stereo=1") !== -1);
                        // Answer and attach
                        self.remoteFeed.createAnswer(
                            {
                                jsep: jsep,
                                tracks: [
                                    { type: 'data' }
                                ],
                                customizeSdp: function(jsep) {
                                    if(stereo && jsep.sdp.indexOf("stereo=1") == -1) {
                                        // Make sure that our offer contains stereo too
                                        jsep.sdp = jsep.sdp.replace("useinbandfec=1", "useinbandfec=1;stereo=1");
                                    }
                                },
                                success: function (jsep) {
                                    Janus.debug("Got SDP!");
                                    Janus.debug(jsep);
                                    var body = {request: "start", room: self.roomId};
                                    self.remoteFeed.send({message: body, jsep: jsep});
                                },
                                error: function (error) {
                                    Janus.error("WebRTC error:", error);
                                }
                            });
                    }
                },
                onlocaltrack: function (track, on) {
                    // The subscriber stream is recvonly, we don't expect anything here
                },
                onremotetrack: function(track, mid, on) {
                    Janus.debug("Remote track (mid=" + mid + ") " + (on ? "added" : "removed") + ":", track);
                    // Which publisher are we getting on this mid?
                    var sub = self.subStreams[mid];
                    let feed_id = null
                    let feed = null;
                    if (typeof sub !== "undefined" && typeof sub.feed_id !== "undefined") {
                        feed_id = sub.feed_id;
                    }

                    if (feed_id) {
                        feed = self.feedStreams[feed_id];
                    }

                    Janus.debug(" >> This track is coming from feed " + feed_id + ":", feed);
                    var slot = self.slots[mid];
                    if(feed && !slot) {
                        slot = feed.slot;
                        self.slots[mid] = feed.slot;
                        self.mids[feed.slot] = mid;
                    }
                    Janus.debug(" >> mid " + mid + " is in slot " + slot);
                    if(!on) {
                        // Track removed, get rid of the stream and the rendering
                        if(track.kind === "video" && feed) {
                            feed.remoteVideos--;
                            if(feed.remoteVideos === 0) {
                                // No video, at least for now: show a placeholder
                            }
                        }
                        delete self.remoteTracks[mid];
                        delete self.slots[mid];
                        delete self.mids[slot];
                        return;
                    }
                    // If we're here, a new track was added
                    if(feed.spinner) {
                        feed.spinner.stop();
                        feed.spinner = null;
                    }


                    if(track.kind === "audio") {
                        // New audio track: create a stream out of it, and use a hidden <audio> element
                        stream = new MediaStream();
                        stream.addTrack(track.clone());
                        self.remoteTracks[mid] = stream;
                        Janus.log("Created remote audio stream:", stream);
                        Janus.attachMediaStream(self.remoteAudio, stream);
                        if (stream.getAudioTracks()) {
                            const janusAudio = new CustomEvent('janus.audio');
                            window.dispatchEvent(janusAudio);
                        }
                        if(feed.remoteVideos === 0) {
                            // No video, at least for now: show a placeholder
                        }
                    } else {
                        // New video track: create a stream out of it
                        feed.remoteVideos++;
                        stream = new MediaStream();
                        stream.addTrack(track.clone());
                        self.remoteTracks[mid] = stream;
                        Janus.log("Created remote video stream:", stream);
                        Janus.attachMediaStream(self.remoteVideo, stream);
                        if (stream.getVideoTracks()) {
                            const janusVideo = new CustomEvent('janus.video');
                            window.dispatchEvent(janusVideo);
                        }
                        // Note: we'll need this for additional videos too
                        if(!self.bitrateTimer[slot]) {
                            self.bitrateTimer[slot] = setInterval(function() {
                                var bitrate = self.remoteFeed.getBitrate(mid);
                                const bitrateEvent = new CustomEvent(
                                    'janus.bitrate',
                                    {
                                        detail: {
                                            bitrate: bitrate
                                        }
                                    }
                                )
                                window.dispatchEvent(bitrateEvent);
                            }, 1000);
                        }
                    }
                },
                oncleanup: function () {
                    Janus.log(" ::: Got a cleanup notification (remote feed) :::");
                    for (var i = 1; i < 6; i++) {
                        if (self.bitrateTimer[i])
                            clearInterval(self.bitrateTimer[i]);

                        self.bitrateTimer[i] = null;
                        const bitrateEvent = new CustomEvent(
                            'janus.bitrate',
                            {
                                detail: {
                                    bitrate: false
                                }
                            }
                        )
                        window.dispatchEvent(bitrateEvent);
                    }
                    for (const index in self.feedStreams) {
                        self.feedStreams[index]['simulcastStarted'] = false;
                        self.feedStreams[index].remoteVideos = 0;
                        self.unsubscribeFrom(index);
                    }
                    self.remoteTracks = {};
                }
            });
    }

    unsubscribeFrom(id) {
        const self = this
        // Unsubscribe from this publisher
        var feed = self.feedStreams[id];
        if (!feed)
            return;
        Janus.debug("Feed " + id + " (" + feed.display + ") has left the room, detaching");
        if (self.bitrateTimer[feed.slot])
            clearInterval(self.bitrateTimer[feed.slot]);
        self.bitrateTimer[feed.slot] = null;
        delete self.simulcastStarted[feed.slot];
        delete self.feeds[feed.slot];
        self.feeds.slot = 0;
        delete self.feedStreams[id];
        // Send an unsubscribe request
        var unsubscribe = {
            request: "unsubscribe",
            streams: [{feed: id}]
        };
        if (self.remoteFeed != null)
            self.remoteFeed.send({message: unsubscribe});
        delete self.subscriptions[id];
    }

     toggleMute() {
        const self = this
        let muted = self.sfutest.isAudioMuted();
        Janus.log((muted ? "Unmuting" : "Muting") + " local stream...");
        if(muted)
            self.sfutest.unmuteAudio();
        else
            self.sfutest.muteAudio();
        muted = self.sfutest.isAudioMuted();
        return muted
    }
}
export default VideoRoom;
