import React from "react";
import {IProps} from "./types";
import fullScreen from "../../assets/img/fullscreen-icon.svg"
import fullScreenOff from "../../assets/img/fullscreen-off-icon.svg"
import pinp from "../../assets/img/pictureInpicture-icon.svg"
import pinpOff from "../../assets/img/pictureinpicture-off-icon.svg"
import videoOff from "../../assets/img/video-off.svg"
import videoOn from "../../assets/img/video-icon.svg"
import voiceOn from "../../assets/img/voice-on.svg"
import voiceOff from "../../assets/img/voice-off.svg"
import endCall from "../../assets/img/endcall.svg"

const CallControl = (props: IProps) => {
    return (
        <div className="">
            <div className="video-controls video-controls-classic">
                <div
                    onClick={(!props.disableMute ? props.toggleStreamVoice : console.log('you dont have a mic'))}
                >
                    <img src={props?.isMute ? voiceOff : voiceOn} alt=""/>
                </div>

                <div onClick={props.endVideo} className="video-controls-classic-endcall">
                    <img src={endCall} alt=""/>
                </div>

                <div
                    onClick={(!props.disableBlank ? props.toggleStreamVideo : console.log('you dont have a cam'))}
                >
                    <img src={props?.isBlank ? videoOff : videoOn} alt=""/>
                </div>

            </div>
            {!props.remoteUserNoCam &&
            <div className="video-controls video-controls-resize">
                <div
                    onClick={props.pictureInPictureVideo}
                >
                    <img src={props?.isPictureInPicture ? pinpOff : pinp} alt=""/>
                </div>
                <div
                    onClick={props.fullScreenVideo}
                >
                    <img src={props?.isFullScreen ? fullScreenOff : fullScreen} alt=""/>
                </div>
            </div>}
        </div>
    )
};

export default CallControl;